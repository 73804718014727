import '../../styles/default.css';
import {
  TopInfoBanner,
  Loader,
  ZemplinLoaderWrapper,
  BreadCrumb,
} from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import * as cookie from 'react-cookies';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import {
  loadFooterData,
  setCurrency,
  loadDefaultSettings,
  setLanguage,
  loadTopMenuBrands,
  loadSitemapSubtreeByUid,
  resetToken,
  setHostname,
} from './actions';
import { CURRENCY_COOKIE } from './constants';
import { dimmerVisibilitySelector, currentThemeSelector } from './selectors';
import Dimmer from '../../components/_helpers/Dimmer/Dimmer';
import { loadCategoriesTree } from '../Header/actions';
import Footer from '../Footer/Footer';
import { GlobalStyles } from '../../theme/globalStyles';
import { LogoMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { checksRedirect } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import { theme } from '../../theme/theme';
import ContainerHeader from '../Header/Header';
import { rem } from 'polished';
import { USER_COOKIE } from 'react-auth';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { ThemeProvider } from '../../theme/styled-components';
import { loadHomeSaleProducts, loadHomeNewProducts } from '../Home/actions';
import { fetchCart } from '../Cart/cartSlice';
import AddedToCartModal from '../Cart/AddedToCartModal';
import BreadCrumbContainer from '../BreadCrumb/BreadCrumb';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import {
  resolveCurrencyByHostname,
  resolveLangByHostname,
} from '../../configureTrans';
import CartErrorModal from '../Cart/CartErrorModal';

interface Props {
  user: any;
  lang: string;
  props: any;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  metaTags: {
    description: string;
    keywords: string;
    og_article_author: string;
  };
  dispatch: any;
  menuItems: Array<{
    active: boolean;
    name: string;
    url: string;
    attribs: any;
  }>;
  isError: boolean;
  dimmerVisible: boolean;
  currentTheme: string;
  isLoaded: boolean;
  token: string;
}

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  min-height: ${rem(600)};

  ${() => theme.mediab.l925`
     min-height: ${rem(400)};
  `}
`;

class App extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, token, user } = props;

    try {
      await dispatch(checksRedirect(props.location));
      await Promise.all[
        (dispatch(setLanguage(resolveLangByHostname(props.hostname))),
        dispatch(setCurrency(resolveCurrencyByHostname(props.hostname))),
        dispatch(setHostname(props.hostname)),
        dispatch(loginUser(cookie.load(USER_COOKIE))))
      ];
      if (process.env.NODE_ENV === 'development') {
        // await dispatch(
        //   loginUser(
        //     'af800ede5ef06a943d9e31b8324598a9a1769cd1f3a4acea5849617fece94f065d44df71ce08841a59d0c4fa0fae4955241bae602f35ef321b8224f90f994109c7040e28ad1d96bf9e5614c8eb7b2836047431c2bb8cfdb8f160947b72d7e1e370eb169bb870b024283a09950b3ece6b56d0d674c10d02ccf56066e32d30b7ad',
        //   ),
        // );
      }

      if (!token || !user) {
        if (cookie.load(USER_COOKIE)) {
          await dispatch(loginUser(cookie.load(USER_COOKIE)));
        } else {
          await dispatch(resetToken());
        }
      }

      await Promise.all[
        (await dispatch(loadDefaultSettings()),
        await dispatch(loadCategoriesTree()),
        await dispatch(loadTopMenuBrands()),
        await dispatch(loadFooterData()),
        await dispatch(loadSitemapSubtreeByUid('BENEFITS')),
        await dispatch(fetchCart()))
      ];

      return {
        props,
        isLoaded: true,
      };
    } catch (exp) {
      console.log(exp);
      return {
        isError: true,
        isLoaded: true,
      };
    }
  }

  public render() {
    const {
      children,
      dimmerVisible,
      socialUrls,
      user,
      currentTheme,
      isLoaded,
      token,
      dispatch,
    } = this.props;

    const currentThemeColors =
      !currentTheme || !theme[currentTheme]
        ? {
            primary: theme.colors.primary,
            navSecondary: theme.colors.navSecondary,
            paginationSecondary: theme.colors.paginationSecondary,
          }
        : {
            primary: theme[currentTheme].primary,
            navSecondary: theme[currentTheme].navSecondary,
            paginationSecondary: theme[currentTheme].paginationSecondary,
          };
    return (
      <React.Fragment>
        <GlobalStyles />

        <LogoMicrodata
          url={'https://www.golfcentrum.sk'}
          logo={
            'https://www.www.golfcentrum.sk/images/golfcentrum/logo-extended.svg'
          }
        />

        <Helmet>
          <script
            async
            src={`https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY}`}
          ></script>
        </Helmet>

        <ThemeProvider
          theme={{
            ...theme,
            colors: {
              ...theme.colors,
              ...currentThemeColors,
            },
          }}
        >
          {this.props.metaTags && (
            <MetaTags
              tags={{
                ...this.props.metaTags,
                description: '',
                keywords: '',
                themeColor: currentThemeColors.primary,
              }}
            />
          )}

          {false && user && token && (
            <TopInfoBanner
              message={
                'Objednávky odoslané do 13:00 odošleme ešte dnes. Zľava 2% pri nákupe cez e-shop.'
              }
            />
          )}
          <ContainerHeader />
          <BreadCrumbContainer />
          <FillSpaceWrapper>
            {!isLoaded ? <ZemplinLoaderWrapper height={300} /> : children}
          </FillSpaceWrapper>
          <Footer socialUrls={socialUrls} />
          {dimmerVisible && <Dimmer height={null} />}
          <AddedToCartModal dispatch={dispatch} />
          <CartErrorModal dispatch={dispatch} />
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    socialUrls: state.general.socialUrls,
    metaTags: state.general.metaTags,
    lang: state.general.lang,
    dimmerVisible: dimmerVisibilitySelector(state),
    currentTheme: currentThemeSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'App' })(App),
);
